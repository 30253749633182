import { all, call, put } from 'redux-saga/effects';
import { scheduleActions } from '../../actions';
import { selectSelectedTimelinePeriodSaga } from '../../selectors-saga';
import { fetchTimelineEntries } from './fetchTimelineEntries';
import { CrewTimelineEntryType } from '../../../../../../../common/types/timeline/CrewTimelineEntryType';
import { AircraftTimelineEntryType } from '../../../../../../../common/types/timeline/AircraftTimelineEntryType';
import { selectCompanySettingsSaga } from '../../../../../../../common/saga/selectors';
import { ContractTimelineEntryType } from '../../../../../../../common/types/timeline/ContractTimelineEntryType';
import { MaintenanceTimelineEntryType } from '../../../../../../../common/types/timeline/MaintenanceTimelineEntryType';

export function* fetchAllEntries() {
  yield put(scheduleActions.setState({ isShowTimelineLoaded: true, allEntryTypesLoaded: false }));
  const timePeriod = yield* selectSelectedTimelinePeriodSaga();
  const [from, to] = [
    timePeriod
      .getStart()
      .subtract(1, 'month')
      .format(),
    timePeriod
      .getEnd()
      .add(1, 'month')
      .format(),
  ];

  yield call(loadAll, from, to);

  yield put(scheduleActions.setState({ allEntryTypesLoaded: true, isShowTimelineLoaded: false }));
}

export function* loadAll(from: string, to: string, isExtension = false) {
  const companySettings = yield* selectCompanySettingsSaga();

  const calls = [
    call(fetchTimelineEntries, from, to, CrewTimelineEntryType.PilotContract, isExtension),
    call(fetchTimelineEntries, from, to, CrewTimelineEntryType.DutyTimes, isExtension),
    call(fetchTimelineEntries, from, to, CrewTimelineEntryType.DutySchedule, isExtension),
    call(fetchTimelineEntries, from, to, CrewTimelineEntryType.CrewSchedule, isExtension),
    call(fetchTimelineEntries, from, to, AircraftTimelineEntryType.AircraftSchedule, isExtension),
    call(fetchTimelineEntries, from, to, AircraftTimelineEntryType.MaintenanceAircraftSchedule, isExtension),
    call(fetchTimelineEntries, from, to, AircraftTimelineEntryType.AircraftContract, isExtension),
    call(fetchTimelineEntries, from, to, MaintenanceTimelineEntryType.MaintenanceCrew, isExtension),
  ];

  if (companySettings.ContractSchedule) {
    calls.push(
      call(
        fetchTimelineEntries,
        from,
        to,
        ContractTimelineEntryType.ClientContractPeriod,
        isExtension,
      ),
    );
  }

  yield all(calls);
}
