import { select } from 'redux-saga/effects';
import {
  getAircraftContractTimelineEntries,
  getAircraftScheduleTimelineEntries,
  getAllEntries,
  getAllEntryTypesLoaded,
  getClientContractPeriodTimelineEntries,
  getContextMenuClickPosition,
  getCopyItem, getCopyItems,
  getCrewScheduleTimelineEntries,
  getCurrentView,
  getCutItem, getCutItems,
  getDutyScheduleTimelineEntries,
  getDutyTimeTimelineEntries,
  getEntryDisplayOptions,
  getIsLoadingMoreEntries,
  getMaintenanceTimelineEntries,
  getMXAircraftScheduleTLEntries,
  getPilotContractTimelineEntries,
  getPreferredVersion,
  getSelectedCrewMemberIDs, getSelectedItems,
  getSelectedTags,
  getSelectedTimelinePeriod,
  getSwitchedTimelinePeriod,
  getVisibleTimelinePeriod,
} from './selectors';

export function* selectSelectedTagsSaga(): Generator<
  any,
  ReturnType<typeof getSelectedTags>,
  ReturnType<typeof getSelectedTags>
> {
  return yield select(getSelectedTags);
}

export function* selectSelectedTimelinePeriodSaga(): Generator<
  any,
  ReturnType<typeof getSelectedTimelinePeriod>,
  ReturnType<typeof getSelectedTimelinePeriod>
> {
  return yield select(getSelectedTimelinePeriod);
}
export function* selectVisibleTimelinePeriodSaga(): Generator<
  any,
  ReturnType<typeof getVisibleTimelinePeriod>,
  ReturnType<typeof getVisibleTimelinePeriod>
> {
  return yield select(getVisibleTimelinePeriod);
}

export function* selectSwitchedTimelinePeriodSaga(): Generator<
  any,
  ReturnType<typeof getSwitchedTimelinePeriod>,
  ReturnType<typeof getSwitchedTimelinePeriod>
> {
  return yield select(getSwitchedTimelinePeriod);
}

export function* selectDutyTimeTimelineEntriesSaga(): Generator<
  any,
  ReturnType<typeof getDutyTimeTimelineEntries>,
  ReturnType<typeof getDutyTimeTimelineEntries>
> {
  return yield select(getDutyTimeTimelineEntries);
}

export function* selectPilotContractTimelineEntriesSaga(): Generator<
  any,
  ReturnType<typeof getPilotContractTimelineEntries>,
  ReturnType<typeof getPilotContractTimelineEntries>
> {
  return yield select(getPilotContractTimelineEntries);
}

export function* selectDutyScheduleTimelineEntriesSaga(): Generator<
  any,
  ReturnType<typeof getDutyScheduleTimelineEntries>,
  ReturnType<typeof getDutyScheduleTimelineEntries>
> {
  return yield select(getDutyScheduleTimelineEntries);
}

export function* selectCrewScheduleTimelineEntriesSaga(): Generator<
  any,
  ReturnType<typeof getCrewScheduleTimelineEntries>,
  ReturnType<typeof getCrewScheduleTimelineEntries>
> {
  return yield select(getCrewScheduleTimelineEntries);
}

export function* selectMaintenanceScheduleTimelineEntriesSaga(): Generator<
  any,
  ReturnType<typeof getMaintenanceTimelineEntries>,
  ReturnType<typeof getMaintenanceTimelineEntries>
> {
  return yield select(getMaintenanceTimelineEntries);
}

export function* selectCurrentViewSaga(): Generator<
  any,
  ReturnType<typeof getCurrentView>,
  ReturnType<typeof getCurrentView>
> {
  return yield select(getCurrentView);
}

export function* selectSelectedCrewMemberIDsSagas(): Generator<
  any,
  ReturnType<typeof getSelectedCrewMemberIDs>,
  ReturnType<typeof getSelectedCrewMemberIDs>
> {
  return yield select(getSelectedCrewMemberIDs);
}

export function* selectAllEntriesSagas(): Generator<
  any,
  ReturnType<typeof getAllEntries>,
  ReturnType<typeof getAllEntries>
> {
  return yield select(getAllEntries);
}

export function* selectAircraftScheduleTimelineEntriesSaga(): Generator<
  any,
  ReturnType<typeof getAircraftScheduleTimelineEntries>,
  ReturnType<typeof getAircraftScheduleTimelineEntries>
> {
  return yield select(getAircraftScheduleTimelineEntries);
}

export function* selectMXAircraftScheduleTLEntriesSaga(): Generator<
  any,
  ReturnType<typeof getMXAircraftScheduleTLEntries>,
  ReturnType<typeof getMXAircraftScheduleTLEntries>
> {
  return yield select(getMXAircraftScheduleTLEntries);
}

export function* selectAircraftContractTimelineEntriesSaga(): Generator<
  any,
  ReturnType<typeof getAircraftContractTimelineEntries>,
  ReturnType<typeof getAircraftContractTimelineEntries>
> {
  return yield select(getAircraftContractTimelineEntries);
}

export function* selectIsLoadingMoreEntriesSaga(): Generator<
  any,
  ReturnType<typeof getIsLoadingMoreEntries>,
  ReturnType<typeof getIsLoadingMoreEntries>
> {
  return yield select(getIsLoadingMoreEntries);
}

export function* selectAllEntryTypesLoadedSaga(): Generator<
  any,
  ReturnType<typeof getAllEntryTypesLoaded>,
  ReturnType<typeof getAllEntryTypesLoaded>
> {
  return yield select(getAllEntryTypesLoaded);
}

export function* selectEntryDisplayOptionsSaga(): Generator<
  any,
  ReturnType<typeof getEntryDisplayOptions>,
  ReturnType<typeof getEntryDisplayOptions>
> {
  return yield select(getEntryDisplayOptions);
}

export function* selectCutItemSaga(): Generator<
  any,
  ReturnType<typeof getCutItem>,
  ReturnType<typeof getCutItem>
> {
  return yield select(getCutItem);
}

export function* selectCopyItemSaga(): Generator<
  any,
  ReturnType<typeof getCopyItem>,
  ReturnType<typeof getCopyItem>
> {
  return yield select(getCopyItem);
}

export function* selectCutItemsSaga(): Generator<
  any,
  ReturnType<typeof getCutItems>,
  ReturnType<typeof getCutItems>
> {
  return yield select(getCutItems);
}

export function* selectCopyItemsSaga(): Generator<
  any,
  ReturnType<typeof getCopyItems>,
  ReturnType<typeof getCopyItems>
> {
  return yield select(getCopyItems);
}

export function* selectContextMenuClickPosition(): Generator<
  any,
  ReturnType<typeof getContextMenuClickPosition>,
  ReturnType<typeof getContextMenuClickPosition>
> {
  return yield select(getContextMenuClickPosition);
}

export function* selectPreferredVersionSaga(): Generator<
  any,
  ReturnType<typeof getPreferredVersion>,
  ReturnType<typeof getPreferredVersion>
> {
  return yield select(getPreferredVersion);
}

export function* selectClientContractPeriodTimelineEntriesSaga(): Generator<
  any,
  ReturnType<typeof getClientContractPeriodTimelineEntries>,
  ReturnType<typeof getClientContractPeriodTimelineEntries>
> {
  return yield select(getClientContractPeriodTimelineEntries);
}
