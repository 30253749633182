import { HandleResizeTimelineItemAction, scheduleActions } from '../../actions';
import { call, delay, put } from 'redux-saga/effects';
import { TimelineItemResizeProps } from '../../../components/Timeline/types';
import { TimelineType } from '../../../types/timeline';
import { showErrorModal } from '../../../legacy/utils/errHandling';
import { CrewTimelineEntryType } from '../../../../../../../common/types/timeline/CrewTimelineEntryType';
import { CrewScheduleTimelineEntry } from '../../../../../../../common/types/timeline/CrewScheduleTimelineEntry';
import {
  selectAircraftScheduleTimelineEntriesSaga,
  selectAircraftContractTimelineEntriesSaga,
  selectCrewScheduleTimelineEntriesSaga,
  selectPilotContractTimelineEntriesSaga,
  selectMaintenanceScheduleTimelineEntriesSaga,
  selectMXAircraftScheduleTLEntriesSaga,
} from '../../selectors-saga';
import { fetchAircraftScheduleEntry, fetchEntry } from '../timelineEntries/fetchEntry';
import { CrewScheduleEntry } from '../../../../../../../redux/pages/crew-scheduling-v3/scheduleEntries/types/CrewScheduleEntry';
import api from '../../../../../../../services/api';
import { selectCompanyIDSaga } from '../../../../../../../common/saga/selectors';
import { AircraftTimelineItem } from '../../../components/Timeline/AircraftTimeline/generator';
import { AircraftTimelineEntryType } from '../../../../../../../common/types/timeline/AircraftTimelineEntryType';
import { AircraftSchedule, MaintenanceAircraftSchedule } from '../../../../../../../common/types/aircraftSchedule';
import { DutyScheduleEntry } from '../../../../../../../redux/pages/crew-scheduling-v3/scheduleEntries/types/DutyScheduleEntry';
import { getDutyEntryStartEnd } from '../../../../../../../common/types/timeline/DutyTimelineEntry';
import { selectFeatureFlagsSaga } from '../../../../../../../redux/common/selectors-saga';
import { AircraftContractTimelineEntry } from '../../../../../../../common/types/timeline/AircraftContractTimelineEntry';
import { AircraftContract } from '../../../../../../../common/types/aircraftContract';
import { adjustDutyTimes } from '../../../helpers';
import { DutyTimeEntry } from '../../../../../../../common/types/dutyEntries';
import { PilotContract } from '../../../../../../../common/types/PilotContract';
import { adjustEntryDetailedFlightTimes } from '../../../legacy/utils/utils';
import moment from 'moment-timezone';
import { MaintenanceTimelineEntryType } from '../../../../../../../common/types/timeline/MaintenanceTimelineEntryType';
import { MaintenanceTimelineEntry } from '../../../../../../../common/types/timeline/MaintenanceEntryType';
import { MaintenanceScheduleEntry } from 'redux/pages/crew-scheduling-v3/scheduleEntries/types/MaintenanceScheduleEntry';

export function* handleResizeTimelineItem(action: HandleResizeTimelineItemAction) {
  const { props } = action.payload;
  yield put(scheduleActions.setTimelineLoading(true));

  try {
    if (props.timelineType === TimelineType.Crew) {
      yield call(handleResizeCrewTimelineItem, props);
    } else if (props.timelineType === TimelineType.Maintenance) {
      yield call(handleResizeMaintenanceTimelineItem, props);
    } else if (props.timelineType === TimelineType.Aircraft) {
      yield call(handleResizeAircraftTimelineItem, props);
    }
  } catch (err) {
    showErrorModal('Failed to resize entry', err);
  } finally {
    yield put(scheduleActions.setTimelineLoading(false));
  }
}

function* handleResizeCrewTimelineItem(props: TimelineItemResizeProps) {
  const { item, time, edge } = props;
  const companyID = yield* selectCompanyIDSaga();
  const featureFlags = yield* selectFeatureFlagsSaga();
  if (item.entryType === CrewTimelineEntryType.CrewSchedule) {
    const currentEntry = item.crewEntry;
    const [newStart, newEnd] = getNewStartEnd(
      moment(currentEntry.StartTime).valueOf(),
      moment(currentEntry.EndTime).valueOf(),
      time,
      edge,
    );

    const updatedEntry: CrewScheduleTimelineEntry = {
      ...currentEntry,
      StartTime: moment(newStart).format(),
      EndTime: moment(newEnd).format(),
    };
    const existedEntries = yield* selectCrewScheduleTimelineEntriesSaga();
    const updatedEntries = existedEntries.map(e => (e.ID === updatedEntry.ID ? updatedEntry : e));
    yield put(scheduleActions.setState({ crewScheduleTimelineEntries: updatedEntries }));

    const fullEntry = (yield fetchEntry(
      CrewTimelineEntryType.CrewSchedule,
      currentEntry.ID,
      false,
    )) as CrewScheduleEntry;
    fullEntry.StartTime = updatedEntry.StartTime;
    fullEntry.EndTime = updatedEntry.EndTime;
    yield call(api.patch,`/v1/companies/${companyID}/crewschedule/update/${fullEntry.ID}`, fullEntry);
    yield put(scheduleActions.reFetchAircraftScheduleEntries(item.crewEntry?.AssignedAircraftIDs));
  } else if (item.entryType === CrewTimelineEntryType.DutySchedule) {
    let fullEntry = (yield fetchEntry(
      CrewTimelineEntryType.DutySchedule,
      item.dutyEntry.ID,
      false,
    )) as DutyScheduleEntry;

    const [currentStart, currentEnd] = getDutyEntryStartEnd(fullEntry);
    const [newStart, newEnd] = getNewStartEnd(currentStart, currentEnd, time, edge);

    fullEntry = adjustDutyTimes(
      fullEntry,
      currentStart,
      currentEnd,
      newStart,
      newEnd,
      featureFlags,
    ) as DutyScheduleEntry;

    const withUpdatedDetailedFlightTimes = adjustEntryDetailedFlightTimes(fullEntry);

    yield call(api.patch,
      `/v1/users/${fullEntry.UserID}/duty-schedule/${fullEntry.ID}/v2`,
      withUpdatedDetailedFlightTimes,
    );
  } else if (item.entryType === CrewTimelineEntryType.DutyTimes) {
    let fullEntry = (yield fetchEntry(
      CrewTimelineEntryType.DutyTimes,
      item.dutyEntry.ID,
      false,
    )) as DutyTimeEntry;

    const [currentStart, currentEnd] = getDutyEntryStartEnd(fullEntry);
    const [newStart, newEnd] = getNewStartEnd(currentStart, currentEnd, time, edge);

    fullEntry = adjustDutyTimes(
      fullEntry,
      currentStart,
      currentEnd,
      newStart,
      newEnd,
      featureFlags,
    ) as DutyTimeEntry;

    const withUpdatedDetailedFlightTimes = adjustEntryDetailedFlightTimes(fullEntry);

    yield call(api.patch,
      `/v1/users/${fullEntry.UserID}/duty/${fullEntry.ID}`,
      withUpdatedDetailedFlightTimes,
    );
  } else if (item.entryType === CrewTimelineEntryType.PilotContract) {
    const currentEntry = item.contractEntry;
    const [currentStart, currentEnd] = [
      currentEntry.StartTime.valueOf(),
      currentEntry.EndTime.valueOf(),
    ];
    const [newStart, newEnd] = getNewStartEnd(currentStart, currentEnd, time, edge);

    const updatedEntry: AircraftContractTimelineEntry = {
      ...currentEntry,
      StartTime: new Date(newStart),
      EndTime: new Date(newEnd),
    };
    const existedEntries = yield* selectPilotContractTimelineEntriesSaga();
    const updatedEntries = existedEntries.map(e => (e.ID === updatedEntry.ID ? updatedEntry : e));
    yield put(scheduleActions.setState({ pilotContractTimelineEntries: updatedEntries }));

    let fullEntry = (yield fetchEntry(
      CrewTimelineEntryType.PilotContract,
      currentEntry.ID,
      false,
    )) as PilotContract;
    fullEntry.StartTime = updatedEntry.StartTime;
    fullEntry.EndTime = updatedEntry.EndTime;

    yield call(api.patch, `/v1/companies/${companyID}/pilotcontract/update/${fullEntry.ID}`, fullEntry);
  }
}

function* handleResizeMaintenanceTimelineItem(props: TimelineItemResizeProps) {
  const { item, time, edge } = props;
  const companyID = yield* selectCompanyIDSaga();
  if (item.entryType === MaintenanceTimelineEntryType.MaintenanceCrew) {
    const currentEntry = item.maintenanceEntry;
    const [newStart, newEnd] = getNewStartEnd(
      moment(currentEntry.StartTime).valueOf(),
      moment(currentEntry.EndTime).valueOf(),
      time,
      edge,
    );

    const updatedEntry: MaintenanceTimelineEntry = {
      ...currentEntry,
      StartTime: moment(newStart).format(),
      EndTime: moment(newEnd).format(),
    };
    const existedEntries = yield* selectMaintenanceScheduleTimelineEntriesSaga();
    const updatedEntries = existedEntries.map(e => (e.ID === updatedEntry.ID ? updatedEntry : e));
    yield put(scheduleActions.setState({ maintenanceTimelineEntries: updatedEntries }));

    const fullEntry = (yield fetchEntry(
      MaintenanceTimelineEntryType.MaintenanceCrew,
      currentEntry.ID,
      false,
    )) as MaintenanceScheduleEntry;
    fullEntry.StartTime = updatedEntry.StartTime;
    fullEntry.EndTime = updatedEntry.EndTime;
    yield api.patch(
      `/v1/companies/${companyID}/maintenance-crew-schedule/update/${fullEntry.ID}`,
      fullEntry,
    );
    yield put(scheduleActions.reFetchMXAircraftScheduleEntries(item.maintenanceEntry?.AssignedAircraftIDs));
  }
}

function* handleResizeAircraftTimelineItem(props: TimelineItemResizeProps) {
  const { item, time, edge } = props;
  let { start_time: currentStart, end_time: currentEnd } = item;
  const [newStart, newEnd] = getNewStartEnd(currentStart, currentEnd, time, edge);

  if (item.entryType == AircraftTimelineEntryType.AircraftSchedule) {
    const companyID = yield* selectCompanyIDSaga();

    const aircraftItem = item as AircraftTimelineItem;
    const entry = aircraftItem.aircraftEntry;
    const updatedAircraftEntry = {
      ...entry,
      StartTime: new Date(newStart),
      EndTime: new Date(newEnd),
    };

    const existedEntries = yield* selectAircraftScheduleTimelineEntriesSaga();
    const updatedEntries = existedEntries.map(e =>
      e.VirtualID === updatedAircraftEntry.VirtualID ? updatedAircraftEntry : e,
    );
    yield put(scheduleActions.setState({ aircraftScheduleTimelineEntries: updatedEntries }));

    const fullEntry = (yield fetchAircraftScheduleEntry(
      AircraftTimelineEntryType.AircraftSchedule,
      item,
      false,
    )) as AircraftSchedule;
    fullEntry.StartTime = updatedAircraftEntry.StartTime;
    fullEntry.EndTime = updatedAircraftEntry.EndTime;

    const bulkUpdatePayload: Partial<
      AircraftSchedule | { UserIDs: string[]; AircraftID: string }
    > = {
      Notes: fullEntry.Notes,
      UserIDs: fullEntry.UserID,
      AircraftID: fullEntry.AssignedAircraft[0],
      StartAirportID: fullEntry.StartAirportID,
      EndAirportID: fullEntry.EndAirportID,
      CompanyScheduleTypeID: fullEntry.CompanyScheduleTypeID,
      Subparts: fullEntry.Subparts,
      StartTime: fullEntry.StartTime,
      EndTime: fullEntry.EndTime,
      // @ts-ignore
      CompanyID: companyID,
      CrewScheduleEntryIds: fullEntry.RelatedCrewScheduleEntries.map(e => e.ID),
    };

    yield call(api.patch, `/v1/companies/${companyID}/crewschedule/bulk-update`, bulkUpdatePayload);
  } else if (item.entryType == AircraftTimelineEntryType.MaintenanceAircraftSchedule) {
    const companyID = yield* selectCompanyIDSaga();

    const aircraftItem = item as AircraftTimelineItem;
    const entry = aircraftItem.maintenanceAircraftEntry;
    const updatedAircraftEntry = {
      ...entry,
      StartTime: new Date(newStart),
      EndTime: new Date(newEnd),
    };

    const existedEntries = yield* selectMXAircraftScheduleTLEntriesSaga();
    const updatedEntries = existedEntries.map(e =>
      e.VirtualID === updatedAircraftEntry.VirtualID ? updatedAircraftEntry : e,
    );
    yield put(scheduleActions.setState({ mxAircraftScheduleTimelineEntries: updatedEntries }));

    const fullEntry = (yield fetchAircraftScheduleEntry(
      AircraftTimelineEntryType.MaintenanceAircraftSchedule,
      item,
      false,
    )) as MaintenanceAircraftSchedule;
    fullEntry.StartTime = updatedAircraftEntry.StartTime;
    fullEntry.EndTime = updatedAircraftEntry.EndTime;

    const bulkUpdatePayload: Partial<
      AircraftSchedule | { UserIDs: string[]; AircraftID: string }
    > = {
      Notes: fullEntry.Notes,
      UserIDs: fullEntry.UserID,
      AircraftID: fullEntry.AssignedAircraft[0],
      CompanyScheduleTypeID: fullEntry.CompanyScheduleTypeID,
      StartTime: fullEntry.StartTime,
      EndTime: fullEntry.EndTime,
      // @ts-ignore
      CompanyID: companyID,
      MaintenanceScheduleEntryIds: fullEntry.RelatedMaintenanceScheduleEntries.map(e => e.ID),
    };

    yield call(api.patch, `/v1/companies/${companyID}/maintenance-crew-schedule/bulk-update`, bulkUpdatePayload);
  } else if (item.entryType == AircraftTimelineEntryType.AircraftContract) {
    const entry = item.contractEntry;
    const companyID = yield* selectCompanyIDSaga();
    const updatedEntry: AircraftContractTimelineEntry = {
      ...entry,
      StartTime: new Date(newStart),
      EndTime: new Date(newEnd),
    };
    const existedEntries = yield* selectAircraftContractTimelineEntriesSaga();
    const updatedEntries = existedEntries.map(e => (e.ID === updatedEntry.ID ? updatedEntry : e));
    yield put(scheduleActions.setState({ aircraftContractTimelineEntries: updatedEntries }));

    let fullEntry = (yield fetchEntry(
      AircraftTimelineEntryType.AircraftContract,
      entry.ID,
      false,
    )) as AircraftContract;
    fullEntry.StartTime = updatedEntry.StartTime;
    fullEntry.EndTime = updatedEntry.EndTime;

    yield call(api.patch,
      `/v1/companies/${companyID}/aircraftcontract/update/${fullEntry.ID}`,
      fullEntry,
    );
  }
}

function getNewStartEnd(
  currentStart: number,
  currentEnd: number,
  time: number,
  edge: TimelineItemResizeProps['edge'],
): [number, number] {
  let newStart = currentStart;
  let newEnd = currentEnd;

  if (edge === 'left') {
    newStart = time;
  } else if (edge === 'right') {
    newEnd = time;
  } else if (edge === 'both') {
    newStart = time;
    newEnd = time + (currentEnd - currentStart);
  }

  return [newStart, newEnd];
}
