import { all, takeEvery, takeLeading } from 'redux-saga/effects';
import actions from './actions';
import { onPageLoadedWorker } from './workers/onPageLoadedWorker';
import { onPageUnloadedWorker } from './workers/onPageUnloadedWorker';
import { switchToViewWorker } from './workers/timeline/switchToViewWorker';
import { switchTimelinePeriodWorker } from './workers/timeline/switchTimelinePeriodWorker';
import { handleItemDoubleClickWorker } from './workers/timelineEntryActions/handleItemDoubleClickWorker';
import { handleMenuItemClickWorker } from './workers/timelineEntryActions/handleMenuItemClickWorker';
import { handleDutyScheduleEntryUpdate } from './workers/pusherSubscriptions/update/handleDutyScheduleEntryUpdate';
import { PusherActionType } from '../../../../../redux/pusher/PusherActions';
import { handleDutyTimeEntryUpdate } from './workers/pusherSubscriptions/update/handleDutyTimeEntryUpdate';
import { handleCrewScheduleEntryUpdate } from './workers/pusherSubscriptions/update/handleCrewScheduleEntryUpdate';
import { handlePilotContractEntryDelete } from './workers/pusherSubscriptions/delete/handlePilotContractEntryDelete';
import { handlePilotContractEntryCreate } from './workers/pusherSubscriptions/create/handlePilotContractEntryCreate';
import { handleJumpToDate } from './workers/timeline/handleJumpToDate';
import { watchSetSelectedTimelines } from './workers/caching/watchSelectedTimelineChange';
import { watchSetVisibleTimelinePeriod } from './workers/timeline/watchSetVisibleTimelinePeriod';
import { actionTypes as commonActionTypes } from '../../../../../redux/common/actions';
import { setSelectedAircraftIdentifiersFromCache } from './workers/caching/setSelectedAircraftIdentifiersFromCache';
import { handlePasteTimelineItem } from './workers/timelineEntryActions/handlePasteTimelineItem';
import { handleDeleteCrewFromAircraft } from './workers/timelineEntryActions/handleDeleteCrewFromAircraft';
import { handleDeleteMXFromAircraft } from './workers/timelineEntryActions/handleDeleteMXFromAircraft';
import { handleCrewScheduleEntryCreate } from './workers/pusherSubscriptions/create/handleCrewScheduleEntryCreate';
import { handleCrewScheduleEntryDelete } from './workers/pusherSubscriptions/delete/handleCrewScheduleEntryDelete';
import { handleResizeTimelineItem } from './workers/timelineEntryActions/handleResizeTimelineItem';
import { reFetchAircraftScheduleEntries } from './workers/timelineEntries/reFetchAircraftScheduleEntries';
import { handleDutyScheduleEntryCreate } from './workers/pusherSubscriptions/create/handleDutyScheduleEntryCreate';
import { handleDutyTimeEntryCreate } from './workers/pusherSubscriptions/create/handleDutyTimeEntryCreate';
import { handleAircraftContractEntryDelete } from './workers/pusherSubscriptions/delete/handleAircraftContractEntryDelete';
import { handleAircraftContractEntryCreate } from './workers/pusherSubscriptions/create/handleAircraftContractEntryCreate';
import { handleAircraftContractEntryUpdate } from './workers/pusherSubscriptions/update/handleAircraftContractEntryUpdate';
import { handlePilotContractEntryUpdate } from './workers/pusherSubscriptions/update/handlePilotContractEntryUpdate';
import { reFetchAllEntries } from './workers/timelineEntries/reFetchAllEntries';
import { handleMoveTimelineItem } from './workers/timelineEntryActions/handleMoveTimelineItem';
import { handleDutyScheduleEntryDelete } from './workers/pusherSubscriptions/delete/handleDutyScheduleEntryDelete';
import { handleDutyTimeEntryDelete } from './workers/pusherSubscriptions/delete/handleDutyTimeEntryDelete';
import { handleSandboxMerge } from './workers/pusherSubscriptions/handleSandboxMerge';
import { saveVirtualAircraftContract } from './workers/contracts/saveVirtualAircraftContract';
import { deleteVirtualAircraftContract } from './workers/contracts/deleteVirtualAircraftContract';
import { handleClientContractPeriodEntryUpdate } from './workers/pusherSubscriptions/put/handleClientContractPeriodEntryUpdate';
import { handleClientContractPeriodEntryDelete } from './workers/pusherSubscriptions/delete/handleClientContractPeriodEntryDelete';
import { handleAircraftContractBulkUpdate } from './workers/pusherSubscriptions/handleAircraftContractBulkUpdate';
import { handleMaintenanceScheduleEntryUpdate } from './workers/pusherSubscriptions/update/handleMaintenanceScheduleEntryUpdate';
import { handleMaintenanceScheduleEntryCreate } from './workers/pusherSubscriptions/create/handleMaintenanceScheduleEntryCreate';
import { handleMaintenanceScheduleEntryDelete } from './workers/pusherSubscriptions/delete/handleMaintenanceScheduleEntryDelete';
import { handlePasteTimelineItems } from './workers/timelineEntryActions/handlePasteTimelineItems';
import { reFetchMXAircraftScheduleEntries } from './workers/timelineEntries/reFetchMXAircraftScheduleEntries';

export default function* rootSaga() {
  yield all([
    takeEvery(actions.ON_PAGE_LOADED, onPageLoadedWorker),
    takeEvery(actions.ON_PAGE_UNLOADED, onPageUnloadedWorker),
    takeEvery(actions.ON_PAGE_UNLOADED, onPageUnloadedWorker),
    takeEvery(actions.SWITCH_TO_VIEW, switchToViewWorker),
    takeEvery(actions.SWITCH_TIMELINE_PERIOD, switchTimelinePeriodWorker),
    takeEvery(actions.HANDLE_TIMELINE_ITEM_DOUBLE_CLICK, handleItemDoubleClickWorker),
    takeEvery(actions.HANDLE_TIMELINE_MENU_ITEM_CLICK, handleMenuItemClickWorker),
    takeEvery(actions.JUMP_TO_DATE, handleJumpToDate),
    takeEvery(actions.SET_SELECTED_TIMELINES, watchSetSelectedTimelines),
    takeEvery(actions.SET_VISIBLE_TIMELINE_PERIOD, watchSetVisibleTimelinePeriod),
    takeEvery(actions.HANDLE_PASTE_TIMELINE_ITEM, handlePasteTimelineItem),
    takeEvery(actions.HANDLE_DELETE_CREW_FROM_AIRCRAFT, handleDeleteCrewFromAircraft),
    takeEvery(actions.HANDLE_PASTE_TIMELINE_ITEMS, handlePasteTimelineItems),
    takeEvery(actions.HANDLE_DELETE_MX_FROM_AIRCRAFT, handleDeleteMXFromAircraft),
    takeEvery(actions.HANDLE_RESIZE_TIMELINE_ITEM, handleResizeTimelineItem),
    takeEvery(actions.RE_FETCH_AIRCRAFT_SCHEDULE_ENTRIES, reFetchAircraftScheduleEntries),
    takeEvery(actions.RE_FETCH_MX_AIRCRAFT_SCHEDULE_ENTRIES, reFetchMXAircraftScheduleEntries),
    takeEvery(actions.RE_FETCH_ALL_ENTRIES, reFetchAllEntries),
    takeEvery(actions.HANDLE_MOVE_TIMELINE_ITEM, handleMoveTimelineItem),

    takeLeading(
      commonActionTypes.SET_AIRCRAFT_TAIL_NUMBERS,
      setSelectedAircraftIdentifiersFromCache,
    ),
    takeEvery(actions.SAVE_VIRTUAL_AIRCRAFT_CONTRACT, saveVirtualAircraftContract),
    takeEvery(actions.DELETE_VIRTUAL_AIRCRAFT_CONTRACT, deleteVirtualAircraftContract),

    takeEvery(PusherActionType.DUTY_SCHEDULE_ENTRY_UPDATED, handleDutyScheduleEntryUpdate),
    takeEvery(PusherActionType.DUTY_TIME_ENTRY_UPDATED, handleDutyTimeEntryUpdate),
    takeEvery(PusherActionType.DUTY_TIME_ENTRY_DELETED, handleDutyTimeEntryDelete),
    takeEvery(PusherActionType.CREW_SCHEDULE_ENTRY_UPDATED, handleCrewScheduleEntryUpdate),
    takeEvery(PusherActionType.CREW_SCHEDULE_ENTRY_CREATED, handleCrewScheduleEntryCreate),
    takeEvery(PusherActionType.CREW_SCHEDULE_ENTRY_DELETED, handleCrewScheduleEntryDelete),

    takeEvery(
      PusherActionType.MAINTENANCE_SCHEDULE_ENTRY_UPDATED,
      handleMaintenanceScheduleEntryUpdate,
    ),
    takeEvery(
      PusherActionType.MAINTENANCE_SCHEDULE_ENTRY_CREATED,
      handleMaintenanceScheduleEntryCreate,
    ),
    takeEvery(
      PusherActionType.MAINTENANCE_SCHEDULE_ENTRY_DELETED,
      handleMaintenanceScheduleEntryDelete,
    ),
    takeEvery(PusherActionType.PILOT_CONTRACT_ENTRY_DELETED, handlePilotContractEntryDelete),
    takeEvery(PusherActionType.PILOT_CONTRACT_ENTRY_CREATED, handlePilotContractEntryCreate),
    takeEvery(PusherActionType.PILOT_CONTRACT_ENTRY_UPDATED, handlePilotContractEntryUpdate),
    takeEvery(PusherActionType.DUTY_SCHEDULE_ENTRY_CREATED, handleDutyScheduleEntryCreate),
    takeEvery(PusherActionType.DUTY_SCHEDULE_ENTRY_DELETED, handleDutyScheduleEntryDelete),
    takeEvery(PusherActionType.DUTY_TIME_ENTRY_CREATED, handleDutyTimeEntryCreate),
    takeEvery(PusherActionType.AIRCRAFT_CONTRACT_ENTRY_DELETED, handleAircraftContractEntryDelete),
    takeEvery(PusherActionType.AIRCRAFT_CONTRACT_ENTRY_CREATED, handleAircraftContractEntryCreate),
    takeEvery(PusherActionType.AIRCRAFT_CONTRACT_ENTRY_UPDATED, handleAircraftContractEntryUpdate),
    takeEvery(PusherActionType.AIRCRAFT_CONTRACTS_BULK_UPDATE, handleAircraftContractBulkUpdate),
    takeEvery(PusherActionType.SANDBOX_OS_MERGED, handleSandboxMerge),
    takeEvery(
      PusherActionType.CLIENT_CONTRACT_PERIOD_CREATED_UPDATED,
      handleClientContractPeriodEntryUpdate,
    ),
    takeEvery(
      PusherActionType.CLIENT_CONTRACT_PERIOD_DELETED,
      handleClientContractPeriodEntryDelete,
    ),
  ]);
}
