import { all } from 'redux-saga/effects';
import user from './user/sagas';
import menu from './menu/sagas';
import settings from './settings/sagas';
import aircraftDetails from './pages/aircraft-details/sagas';
import aircraft from './pages/aircraft/sagas';
import aircraftLog from './pages/aircraft-log/sagas';
import dutyTimes from './pages/duty-times/sagas';
import flightTimes from './pages/flight-times/sagas';
import currency from './pages/currency/sagas';
import crewCompliance from './pages/crew-compliance/sagas';
import common from './common/sagas';
import crewScheduling from './pages/crew-scheduling-v3/sagas';
import dutyScheduleModal from './pages/crew-scheduling-v3/modals/DutyScheduleEntryModal/sagas';
import scheduleEntryModal from './pages/crew-scheduling-v3/modals/ScheduleEntryModal/sagas';
import maintenanceEntryModal from './pages/crew-scheduling-v3/modals/MaintenanceEntryModal/sagas';
import dutyTimeModal from './pages/crew-scheduling-v3/modals/DutyTimeEntryModal/sagas';
import crewScheduleEntries from './pages/crew-scheduling-v3/scheduleEntries/sagas';
import crewScheduleMobile from '../pages/data/organization/crew-scheduling-mobile/redux-saga/sagas';
import numInputMobile from '../components/shared/mobile/ATNumInput/redux-saga/sagas';
import flightWatch from '../pages/data/organization/flight-watch/redux-saga/sagas';
import aircraftLogSelector from '../components/shared/AircraftLogSelector/redux-saga/sagas';
import dutyFileImport from '../pages/data/organization/crew-scheduling-v3/components/Integrations/FileImport/redux-saga/sagas';
import combinedMaintenance from '../pages/data/aircraft-details/CombinedMaintenanceItems/redux-saga/sagas';
import pilotStatus from '../pages/data/organization/pilot-status/redux-saga/sagas';
import aircraftGroupSchedule from '../pages/data/organization/crew-scheduling-v3/components/GroupSchedule/redux-saga/sagas';
import companyLibrary from '../pages/data/organization/company-library/redux-saga/sagas';
import customForms from '../pages/data/organization/custom-forms/redux-saga/sagas';
import crewSchedulingSaga from '../pages/data/organization/crew-scheduling-v3/redux-saga/sagas';
import pusherSagas from './pusher/sagas';
import scheduleVersioningSaga from '../pages/data/organization/crew-scheduling-v3/components/Versioning/redux-saga/sagas';
import integrations from './integrations/sagas';
import settingsPage from '../pages/data/organization/settings/redux-saga/sagas.ts';
import bulkScheduleModal from '../pages/data/organization/crew-scheduling-v3/components/Modals/BulkScheduleModal/redux-saga/sagas.ts';
import bulkMaintenanceScheduleModal from '../pages/data/organization/crew-scheduling-v3/components/Modals/BulkMaintenanceScheduleModal/redux-saga/sagas.ts';
import clientContractPeriodModal from '../pages/data/organization/crew-scheduling-v3/components/Modals/ClientContractPeriodModal/redux-saga/sagas.ts';


export default function* rootSaga() {
  yield all([
    user(),
    menu(),
    settings(),
    aircraftLog(),
    aircraftDetails(),
    aircraft(),
    dutyTimes(),
    flightTimes(),
    currency(),
    crewCompliance(),
    common(),
    crewScheduling(),
    dutyScheduleModal(),
    scheduleEntryModal(),
    maintenanceEntryModal(),
    dutyTimeModal(),
    crewScheduleEntries(),
    crewScheduleMobile(),
    numInputMobile(),
    flightWatch(),
    aircraftLogSelector(),
    dutyFileImport(),
    combinedMaintenance(),
    pilotStatus(),
    aircraftGroupSchedule(),
    companyLibrary(),
    customForms(),
    crewSchedulingSaga(),
    pusherSagas(),
    scheduleVersioningSaga(),
    integrations(),
    settingsPage(),
    bulkScheduleModal(),
    bulkMaintenanceScheduleModal(),
    clientContractPeriodModal(),
  ]);
}
