import MODAL_MODE from '../../../pages/data/common/constants';

const actions = {
  OPEN_MODAL: 'pages/training-items/OPEN_MODAL',
  CLOSE_MODAL: 'pages/training-items/CLOSE_MODAL',
};

export interface OpenTrainingItemModalAction {
  type: typeof actions.OPEN_MODAL;
  payload: { mode: MODAL_MODE };
}

export interface CloseTrainingItemModalAction {
  type: typeof actions.CLOSE_MODAL;
}

export const openTrainingItemModalAction = (mode: MODAL_MODE): OpenTrainingItemModalAction => ({
  type: actions.OPEN_MODAL,
  payload: { mode },
});

export const closeTrainingItemModalAction = (): CloseTrainingItemModalAction => ({
  type: actions.CLOSE_MODAL,
});


export default actions;
