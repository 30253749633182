export enum MenuItemType {
  View = 'view',
  Copy = 'copy',
  Cut = 'cut',
  Edit = 'edit',
  Delete = 'delete',
  BulkCopy = 'bulk-copy',
  BulkCut = 'bulk-cut',
  BulkDelete = 'bulk-delete',
  BulkPaste = 'bulk-patse',
  GenerateSchedule = 'generate-schedule',
  ShowAssociatedCrew = 'show-associated-crew',
  ShowAssociatedMaintenance = 'show-associated-maintenance',
  ShowAssociatedAircraft = 'show-associated-aircraft',
  UnassignContract = 'unassign-contract',
  UnassignAircraft = 'unassign-aircraft',
}
