import { bulkModalEntryActions, SaveCSGroupedEntryAction } from '../actions';
import { put } from 'redux-saga/effects';
import { scheduleActions } from '../../../../../redux-saga/actions';
import api from '../../../../../../../../../services/api';
import { selectCompanyIDSaga } from '../../../../../../../../../common/saga/selectors';
import MODAL_MODE from '../../../../../../../common/constants';
import { normalizeDateSeconds } from '../../../../../../../../../common/helpers/daysCalc';
import moment from 'moment-timezone';
import { BulkScheduleModalValues } from '../../../../types';
import { UpdateCrewScheduleBulkRequest } from '../types';
import { BulkScheduleGroupedByField } from '../../Base/types';

export function* handleSaveCSGroupedEntry(action: SaveCSGroupedEntryAction) {
  const { formValues, mode, groupedBy } = action.payload;

  const companyID = yield* selectCompanyIDSaga();

  yield put(bulkModalEntryActions.setSaveError(null));

  if (formValues.Shifts.length > 0) {
    const requests = [];
    const aircraftToFetch = [];

    formValues.Shifts.forEach(shift => {
      const payload = serializeEntry(shift);

      if (payload.AircraftID) {
        aircraftToFetch.push(payload.AircraftID);
      }

      if (shift?.AssignedAircraftID) {
        aircraftToFetch.push(shift.AssignedAircraftID);
      }

      requests.push(api.patch(`/v1/companies/${companyID}/crewschedule/bulk-update`, payload));
    });

    if (requests) {
      try {
        yield put(bulkModalEntryActions.setIsSaving(true));
        yield Promise.all(requests);
        if (groupedBy === BulkScheduleGroupedByField.Aircraft) {
          yield put(
            bulkModalEntryActions.setAircraftModalMode({ open: false, mode: MODAL_MODE.create }),
          );
        } else if (groupedBy === BulkScheduleGroupedByField.ClientContract) {
          yield put(
            bulkModalEntryActions.setContractModalMode({ open: false, mode: MODAL_MODE.create }),
          );
        }

        yield put(scheduleActions.reFetchAircraftScheduleEntries(aircraftToFetch));
        yield put(scheduleActions.setEditAircraftScheduleEntry({}));
      } catch (err) {
        console.log(err);
        yield put(
          bulkModalEntryActions.setSaveError({
            title: 'Could Not Save Schedule Entry',
            message:
              'Please ensure this pilot is not already scheduled for this time. If this issue persists, contact support.',
          }),
        );
      } finally {
        yield put(bulkModalEntryActions.setIsSaving(false));
      }
    }
  }
}

const serializeEntry = (values: BulkScheduleModalValues): UpdateCrewScheduleBulkRequest => {
  const endTime = normalizeDateSeconds(moment(values.SchedulePeriod?.[1]));
  return {
    Notes: values.Notes,
    UserIDs: values.UserIDs,
    CrewScheduleEntryIds: values.CrewScheduleEntryIDs,
    StartTime: moment(values.SchedulePeriod?.[0])
      .seconds(0)
      .format(),
    EndTime: endTime.format(),
    Subparts: values.SubPart ? [values.SubPart] : null,
    StartAirportID: values.StartAirportID?.length > 0 ? values.StartAirportID : null,
    EndAirportID: values.EndAirportID?.length > 0 ? values.EndAirportID : null,
    AircraftID: values.AssignedAircraftID,
    CompanyScheduleTypeID: values.CompanyScheduleEntryTypeID,
    ClientContractID: values.ClientContractID,
    ScheduleRoleIDs: values.ScheduleRoleIDs,
  };
};
