import { call, put } from 'redux-saga/effects';
import api from '../../../../../../../services/api';
import {
  fetchReviewTypes,
  setReviewTypes,
} from '../../../../../aircraft-log/Review/ReviewModal/actions';

export function* handleFetchReviewTypes(action: fetchReviewTypes){
  const { companyID } = action.payload;

  let reviewTypes = [];

  try {
    const {
      data: { Data },
    } = yield call(
      api.get,
      `/v1/aircraft-logs/${companyID}/reviewTypes`,
      {
        params: {
          company_id: companyID,
        },
      },
    );
    reviewTypes = Data;
  } catch (err) {
    console.log(`Error fetching reviews`, err);
  } finally{
    yield put(setReviewTypes(reviewTypes));
  }
}
