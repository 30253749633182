export enum AircraftTimelineEntryType {
  AircraftSchedule = 'aircraft-schedule',
  MaintenanceAircraftSchedule = 'maintenance-aircraft-schedule',
  AircraftContract = 'aircraftcontract',
  MeasuringTape = 'aircraft-measuring-tape'
}

const sortOrder = [
  AircraftTimelineEntryType.AircraftSchedule,
  AircraftTimelineEntryType.MaintenanceAircraftSchedule,
  // AircraftTimelineEntryType.AircraftContract,
];
