import MODAL_MODE from '../../../pages/data/common/constants';
import { ModalMode } from '../crew-scheduling-v3/modals/interfaces';
import actions from './actions';
import { CertificateGroup } from '../../../pages/data/organization/tracked-items/types';

export interface CertificateGroupsModalInitialState {
  modalMode: ModalMode;
  certificateGroups: CertificateGroup[];
}

const initialState: CertificateGroupsModalInitialState = {
  modalMode: { open: false, mode: MODAL_MODE.create },
  certificateGroups: [],
};

export default function reducers(state: CertificateGroupsModalInitialState = initialState, action) {
  switch (action.type) {
    case actions.OPEN_CERTIFICATE_GROUPS_MODAL:
      return { ...state, modalMode: { open: true, mode: action.payload.mode } };
    case actions.CLOSE_CERTIFICATE_GROUPS_MODAL:
      return { ...state, modalMode: { open: false, mode: MODAL_MODE.create } };
    case actions.SET_CERTIFICATE_GROUPS:
      return { ...state, certificateGroups: action.payload.certificateGroups };
    default:
      return state;
  }
}
