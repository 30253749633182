import { isMobile } from 'react-device-detect';
import { FeatureFlag } from './feature-flags/FeatureFlagsProvider';

/**
 * Determine if a `childPath` is in `parentPath`.
 * E.g. child: /dic/file/a/123.html, parent: /dict/file/ should return True
 * child: /dic/file/a/123.html, parent: /dict/file should return True
 * child: /dic/file_folder/a/123.html, parent: /dict/file should return False
 *
 * @param {*} childPath
 * @param {*} parentPath
 * @returns
 */
const isPathInside = (childPath: string, parentPath: string) => {
  const normalizedParentPath =
    parentPath[parentPath.length - 1] === '/' ? parentPath : `${parentPath}/`;
  return childPath.startsWith(normalizedParentPath) && childPath.startsWith(normalizedParentPath);
};

type RoleTypes =
  | 'owner'
  | 'admin'
  | 'engineer'
  | 'pilot'
  | 'user'
  | 'viewOnly'
  | 'subContractor'
  | 'reportViewer'
  | 'crewScheduleViewer'
  | 'taggedScheduleViewer'
  | 'reviewer';

export const getRedirectPath = (
  isLoginLayout: boolean,
  isUserAuthenticated: boolean,
  featureFlags: FeatureFlag,
  settings: {
    CrewSchedulingOnly: boolean;
    CrewSchedulingV1: boolean;
    IntegrateLoggingWithCrewScheduleV2: boolean;
    ShowDesktopScheduleOnMobile: boolean;
    NonAdminCanViewCrewSchedule: boolean;
    ShowAircraftPage: boolean;
  },
  roles: RoleTypes[],
) => {
  // redirect to login page if current is not login page and user not authenticated
  if (!isLoginLayout && !isUserAuthenticated) {
    return '/user/login';
  }

  // direct to appropriate page for the role if the user is on login page and authenticated or not on login page
  if (isUserAuthenticated) {
    if (roles.includes('admin') || roles.includes('viewOnly') || roles.includes('subContractor')) {
      if (settings?.CrewSchedulingOnly && settings?.CrewSchedulingV1) {
        return '/data/organization/crew-scheduling';
      }

      if (settings?.CrewSchedulingOnly && !settings?.CrewSchedulingV1) {
        if (isMobile && featureFlags && featureFlags[FeatureFlag.CrewScheduleMobile]) {
          if (isLoginLayout) {
            return '/data/organization/crew-scheduling-mobile';
          }
          if (settings.ShowDesktopScheduleOnMobile) {
            return '/data/organization/crew-scheduling-v3';
          }
        }
        if (isMobile && featureFlags && !featureFlags[FeatureFlag.CrewScheduleMobile]) {
          return '/data/organization/crew-scheduling-v3';
        }
        return '/data/organization/crew-scheduling-v3';
      }

      return '/data/organization/fleet-overview';
    } else if (roles.includes('engineer') && settings?.ShowAircraftPage) {
      return '/data/organization/aircraft';
    } else if (roles.includes('engineer') && !settings?.ShowAircraftPage) {
      return '/data/organization/fleet-overview';
    } else if (
      roles.includes('pilot') ||
      roles.includes('crewScheduleViewer') ||
      roles.includes('taggedScheduleViewer')
    ) {
      // show flight and duty page if the crew schedule page is not visible
      if (
        (settings?.CrewSchedulingOnly && !settings?.NonAdminCanViewCrewSchedule) ||
        !settings?.CrewSchedulingOnly
      ) {
        return '/profile/duty';
      }

      if (isMobile && isLoginLayout) {
        return '/data/organization/crew-scheduling-mobile';
      }
      return '/data/organization/crew-scheduling-v3';
    } else if (roles.includes('reportViewer')) {
      return '/data/organization/reports';
    } else if (roles.includes('user')) {
      return '/profile/manage';
    }
  }

  return null;
};

/**
 * Return the `path` that's inside `paths` array. If there's no exact match in `paths` for `path` treat
 * `path` as a child path and try to match it with a parent path in `paths`.
 * For example '/data/users/123' would match `/data/users` if that `paths` contains `/data/users` as an element
 * @param {*} path String Path or child path
 * @param {*} paths String Array of paths
 * @return false | string
 */
const pathInPaths = (path: string, paths: string[]) => {
  for (let i = 0; i < paths.length; i += 1) {
    if (path === paths[i]) {
      return paths[i];
    }

    if (isPathInside(path, paths[i])) {
      return paths[i];
    }
    // eslint-disable-next-line no-continue
    continue;
  }

  return false;
};

export const isPathAllowedForRole = (
  roles: RoleTypes[],
  pathname: string,
  settings: {
    CrewSchedulingOnly?: boolean;
    AerochexEnabled?: boolean;
    CrewSchedulingV1?: boolean;
    IntegrateLoggingWithCrewScheduleV2?: boolean;
    NonAdminCanViewCrewSchedule?: boolean;
    ShowAircraftPage?: boolean;
    ShowReportsPage?: boolean;
    ShowCrewCompliancePage?: boolean;
    EnableRestAssureIndicator?: boolean;
    CompanyLibrary?: boolean;
    EnableCustomForms?: boolean;
    ManualTrainingItems?: boolean
  },
) => {
  if (!roles) {
    return false;
  }
  /** {@link} src/router.js */
  const rolePathMap: {
    [key in RoleTypes]: {
      [key: string]:
        | boolean
        | {
            CrewSchedulingOnly?: boolean;
            AerochexEnabled?: boolean;
            CrewSchedulingV1?: boolean;
            IntegrateLoggingWithCrewScheduleV2?: boolean;
            NonAdminCanViewCrewSchedule?: boolean;
            ShowAircraftPage?: boolean;
            ShowReportsPage?: boolean;
            ShowCrewCompliancePage?: boolean;
            EnableRestAssureIndicator?: boolean;
            CompanyLibrary?: boolean;
            EnableCustomForms?: boolean;
            EnableLegacyPilotSchedule?: boolean;
            ManualTrainingItems?: boolean
          }[];
    };
  } = {
    owner: {
      '/data/organization/dashboard': [
        // the following will evaluate as
        // (CrewSchedulingOnly == false) || (CrewSchedulingOnly === true && IntegrateLoggingWithCrewScheduleV2 === true)
        { CrewSchedulingOnly: false },
        { CrewSchedulingOnly: true, IntegrateLoggingWithCrewScheduleV2: true },
      ],
      '/data/aircraft': true,
      '/data/aircraft/logs': true,
      '/data/users': true,
      '/data/organization/regulations': [{ CrewSchedulingOnly: true, CrewSchedulingV1: false }],
      '/data/organization/users': true,
      '/data/organization/aircraft': [
        { CrewSchedulingOnly: false, ShowAircraftPage: true },
        {
          CrewSchedulingOnly: true,
          IntegrateLoggingWithCrewScheduleV2: true,
          ShowAircraftPage: true,
        },
      ],
      '/data/organization/tracked-items': [{ ManualTrainingItems: true }],
      '/data/organization/fleet-overview': [
        { CrewSchedulingOnly: false, ShowAircraftPage: false },
        {
          CrewSchedulingOnly: true,
          IntegrateLoggingWithCrewScheduleV2: true,
          ShowAircraftPage: false,
        },
      ],
      '/data/organization/logs': [
        { CrewSchedulingOnly: false },
        { CrewSchedulingOnly: true, IntegrateLoggingWithCrewScheduleV2: true },
      ],
      '/data/organization/crew-scheduling': [{ CrewSchedulingOnly: true, CrewSchedulingV1: true }],
      '/data/organization/crew-scheduling-v3': [
        { CrewSchedulingOnly: true, CrewSchedulingV1: false },
      ],
      '/data/organization/crew-scheduling-mobile': [
        { CrewSchedulingOnly: true, CrewSchedulingV1: false },
      ],
      '/data/organization/duty-times': [
        { CrewSchedulingOnly: false },
        { CrewSchedulingOnly: true, IntegrateLoggingWithCrewScheduleV2: true },
      ],
      '/data/organization/crew-compliance': [
        { CrewSchedulingOnly: false },
        { CrewSchedulingOnly: true, ShowCrewCompliancePage: true },
        { CrewSchedulingOnly: true, IntegrateLoggingWithCrewScheduleV2: true },
      ],
      '/data/organization/aerochex': [{ AerochexEnabled: true }],
      '/data/airports': [
        { CrewSchedulingOnly: false },
        { CrewSchedulingOnly: true, IntegrateLoggingWithCrewScheduleV2: true },
      ],
      '/data/organization/settings': [{ CrewSchedulingOnly: true }],
      '/data/organization/flight-watch': true,
      '/data/organization/pilot-status': [{ EnableRestAssureIndicator: true }],
      '/data/organization/company-library': [{ CompanyLibrary: true }],
      '/data/organization/custom-forms': [{ EnableCustomForms: true }],
    },
    admin: {
      '/data/organization/dashboard': [
        { CrewSchedulingOnly: false },
        { CrewSchedulingOnly: true, IntegrateLoggingWithCrewScheduleV2: true },
      ],
      '/data/organization/tracked-items': [{ ManualTrainingItems: true }],
      '/data/aircraft': true,
      '/data/aircraft/logs': true,
      '/data/users': true,
      '/data/organization/regulations': [{ CrewSchedulingOnly: true, CrewSchedulingV1: false }],
      '/data/organization/users': true,
      '/data/organization/aircraft': [
        { CrewSchedulingOnly: false, ShowAircraftPage: true },
        {
          CrewSchedulingOnly: true,
          IntegrateLoggingWithCrewScheduleV2: true,
          ShowAircraftPage: true,
        },
      ],
      '/data/organization/fleet-overview': [
        { CrewSchedulingOnly: false, ShowAircraftPage: false },
        {
          CrewSchedulingOnly: true,
          IntegrateLoggingWithCrewScheduleV2: true,
          ShowAircraftPage: false,
        },
      ],
      '/data/organization/logs': [
        { CrewSchedulingOnly: false },
        { CrewSchedulingOnly: true, IntegrateLoggingWithCrewScheduleV2: true },
      ],
      '/data/organization/crew-scheduling': [{ CrewSchedulingOnly: true, CrewSchedulingV1: true }],
      '/data/organization/crew-scheduling-v3': [
        { CrewSchedulingOnly: true, CrewSchedulingV1: false },
      ],
      '/data/organization/crew-scheduling-mobile': [
        { CrewSchedulingOnly: true, CrewSchedulingV1: false },
      ],
      '/data/organization/duty-times': [
        { CrewSchedulingOnly: false },
        { CrewSchedulingOnly: true, IntegrateLoggingWithCrewScheduleV2: true },
      ],
      '/data/organization/crew-compliance': [
        { CrewSchedulingOnly: false },
        { CrewSchedulingOnly: true, ShowCrewCompliancePage: true },
        { CrewSchedulingOnly: true, IntegrateLoggingWithCrewScheduleV2: true },
      ],
      '/data/organization/aerochex': [{ AerochexEnabled: true }],
      '/data/airports': [
        { CrewSchedulingOnly: false },
        { CrewSchedulingOnly: true, IntegrateLoggingWithCrewScheduleV2: true },
      ],
      '/data/organization/reports': [{ ShowReportsPage: true }],
      '/data/organization/settings': [{ CrewSchedulingOnly: true }],
      '/data/organization/flight-watch': true,
      '/data/organization/pilot-status': [{ EnableRestAssureIndicator: true }],
      '/data/organization/company-library': [{ CompanyLibrary: true }],
      '/data/organization/custom-forms': [{ EnableCustomForms: true }],
    },
    reportViewer: {
      '/data/organization/dashboard': [
        { CrewSchedulingOnly: false },
        { CrewSchedulingOnly: true, IntegrateLoggingWithCrewScheduleV2: true },
      ],
      '/data/aircraft': true,
      '/data/aircraft/logs': true,
      '/data/organization/aircraft': [
        { CrewSchedulingOnly: false, ShowAircraftPage: true },
        {
          CrewSchedulingOnly: true,
          IntegrateLoggingWithCrewScheduleV2: true,
          ShowAircraftPage: true,
        },
      ],
      '/data/organization/fleet-overview': [
        { CrewSchedulingOnly: false, ShowAircraftPage: false },
        {
          CrewSchedulingOnly: true,
          IntegrateLoggingWithCrewScheduleV2: true,
          ShowAircraftPage: false,
        },
      ],
      '/data/organization/logs': [
        { CrewSchedulingOnly: false },
        { CrewSchedulingOnly: true, IntegrateLoggingWithCrewScheduleV2: true },
      ],
      '/data/organization/crew-scheduling': [{ CrewSchedulingOnly: true, CrewSchedulingV1: true }],
      '/data/organization/crew-scheduling-v3': [
        { CrewSchedulingOnly: true, CrewSchedulingV1: false },
      ],
      '/data/organization/crew-scheduling-mobile': [
        { CrewSchedulingOnly: true, CrewSchedulingV1: false },
      ],
      '/data/organization/duty-times': [
        { CrewSchedulingOnly: false },
        { CrewSchedulingOnly: true, IntegrateLoggingWithCrewScheduleV2: true },
      ],
      '/data/organization/crew-compliance': [
        { CrewSchedulingOnly: false },
        { CrewSchedulingOnly: true, ShowCrewCompliancePage: true },
        { CrewSchedulingOnly: true, IntegrateLoggingWithCrewScheduleV2: true },
      ],
      '/data/organization/aerochex': [{ AerochexEnabled: true }],
      '/data/airports': [
        { CrewSchedulingOnly: false },
        { CrewSchedulingOnly: true, IntegrateLoggingWithCrewScheduleV2: true },
      ],
      '/data/organization/reports': [{ ShowReportsPage: true }],
    },
    // View Only will be able to view the same pages as the admin but not be able to edit them
    viewOnly: {
      '/data/organization/dashboard': [
        { CrewSchedulingOnly: false },
        { CrewSchedulingOnly: true, IntegrateLoggingWithCrewScheduleV2: true },
      ],
      '/data/aircraft': true,
      '/data/aircraft/logs': true,
      '/data/users': true,
      '/data/organization/regulations': [{ CrewSchedulingOnly: true, CrewSchedulingV1: false }],
      '/data/organization/users': true,
      '/data/organization/aircraft': [
        { CrewSchedulingOnly: false, ShowAircraftPage: true },
        {
          CrewSchedulingOnly: true,
          IntegrateLoggingWithCrewScheduleV2: true,
          ShowAircraftPage: true,
        },
      ],
      '/data/organization/fleet-overview': [
        { CrewSchedulingOnly: false, ShowAircraftPage: false },
        {
          CrewSchedulingOnly: true,
          IntegrateLoggingWithCrewScheduleV2: true,
          ShowAircraftPage: false,
        },
      ],
      '/data/organization/logs': [
        { CrewSchedulingOnly: false },
        { CrewSchedulingOnly: true, IntegrateLoggingWithCrewScheduleV2: true },
      ],
      '/data/organization/crew-scheduling': [{ CrewSchedulingOnly: true, CrewSchedulingV1: true }],
      '/data/organization/crew-scheduling-v3': [
        { CrewSchedulingOnly: true, CrewSchedulingV1: false },
      ],
      '/data/organization/crew-scheduling-mobile': [
        { CrewSchedulingOnly: true, CrewSchedulingV1: false },
      ],
      '/data/organization/duty-times': [
        { CrewSchedulingOnly: false },
        { CrewSchedulingOnly: true, IntegrateLoggingWithCrewScheduleV2: true },
      ],
      '/data/organization/crew-compliance': [
        { CrewSchedulingOnly: false },
        { CrewSchedulingOnly: true, ShowCrewCompliancePage: true },
        { CrewSchedulingOnly: true, IntegrateLoggingWithCrewScheduleV2: true },
      ],
      '/data/organization/aerochex': [{ AerochexEnabled: true }],
      '/data/airports': [
        { CrewSchedulingOnly: false },
        { CrewSchedulingOnly: true, IntegrateLoggingWithCrewScheduleV2: true },
      ],
      '/data/settings': true,
      '/data/organization/flight-watch': true,
      '/data/organization/pilot-status': [{ EnableRestAssureIndicator: true }],
      '/data/organization/company-library': [{ CompanyLibrary: true }],
      '/data/organization/custom-forms': [{ EnableCustomForms: true }],
    },
    reviewer : {
      '/data/organization/dashboard': [
        { CrewSchedulingOnly: false },
        { CrewSchedulingOnly: true, IntegrateLoggingWithCrewScheduleV2: true },
      ],
      '/data/aircraft': true,
      '/data/aircraft/logs': true,
      '/data/users': true,
      '/data/organization/regulations': [{ CrewSchedulingOnly: true, CrewSchedulingV1: false }],
      '/data/organization/users': true,
      '/data/organization/aircraft': [
        { CrewSchedulingOnly: false, ShowAircraftPage: true },
        {
          CrewSchedulingOnly: true,
          IntegrateLoggingWithCrewScheduleV2: true,
          ShowAircraftPage: true,
        },
      ],
      '/data/organization/fleet-overview': [
        { CrewSchedulingOnly: false, ShowAircraftPage: false },
        {
          CrewSchedulingOnly: true,
          IntegrateLoggingWithCrewScheduleV2: true,
          ShowAircraftPage: false,
        },
      ],
      '/data/organization/logs': [
        { CrewSchedulingOnly: false },
        { CrewSchedulingOnly: true, IntegrateLoggingWithCrewScheduleV2: true },
      ],
      '/data/organization/crew-scheduling': [{ CrewSchedulingOnly: true, CrewSchedulingV1: true }],
      '/data/organization/crew-scheduling-v3': [
        { CrewSchedulingOnly: true, CrewSchedulingV1: false },
      ],
      '/data/organization/crew-scheduling-mobile': [
        { CrewSchedulingOnly: true, CrewSchedulingV1: false },
      ],
      '/data/organization/duty-times': [
        { CrewSchedulingOnly: false },
        { CrewSchedulingOnly: true, IntegrateLoggingWithCrewScheduleV2: true },
      ],
      '/data/organization/crew-compliance': [
        { CrewSchedulingOnly: false },
        { CrewSchedulingOnly: true, ShowCrewCompliancePage: true },
        { CrewSchedulingOnly: true, IntegrateLoggingWithCrewScheduleV2: true },
      ],
      '/data/organization/aerochex': [{ AerochexEnabled: true }],
      '/data/airports': [
        { CrewSchedulingOnly: false },
        { CrewSchedulingOnly: true, IntegrateLoggingWithCrewScheduleV2: true },
      ],
      '/data/settings': true,
      '/data/organization/flight-watch': true,
      '/data/organization/pilot-status': [{ EnableRestAssureIndicator: true }],
      '/data/organization/company-library': [{ CompanyLibrary: true }],
      '/data/organization/custom-forms': [{ EnableCustomForms: true }],
    },
    engineer: {
      '/data/aircraft/logs': [
        { CrewSchedulingOnly: false },
        { CrewSchedulingOnly: true, IntegrateLoggingWithCrewScheduleV2: true },
      ],
      '/data/aircraft': true,
      '/data/organization/aircraft': [
        { CrewSchedulingOnly: false, ShowAircraftPage: true },
        {
          CrewSchedulingOnly: true,
          IntegrateLoggingWithCrewScheduleV2: true,
          ShowAircraftPage: true,
        },
      ],
      '/data/organization/fleet-overview': [
        { CrewSchedulingOnly: false, ShowAircraftPage: false },
        {
          CrewSchedulingOnly: true,
          IntegrateLoggingWithCrewScheduleV2: true,
          ShowAircraftPage: false,
        },
      ],
      '/data/organization/logs': true,
      '/data/organization/aerochex': [{ AerochexEnabled: true }],
      '/data/organization/crew-scheduling': [
        { CrewSchedulingOnly: true, CrewSchedulingV1: true, NonAdminCanViewCrewSchedule: true },
      ],
      '/data/organization/crew-scheduling-v3': [
        { CrewSchedulingOnly: true, CrewSchedulingV1: false, NonAdminCanViewCrewSchedule: true },
      ],
      '/data/organization/crew-scheduling-mobile': [
        { CrewSchedulingOnly: true, CrewSchedulingV1: false, NonAdminCanViewCrewSchedule: true },
      ],
    },
    pilot: {
      '/data/aircraft/logs': true,
      '/data/aircraft': true,
      '/data/organization/tracked-items': [{ ManualTrainingItems: true }],
      '/profile/duty': [
        { CrewSchedulingOnly: true, NonAdminCanViewCrewSchedule: false },
        { CrewSchedulingOnly: false },
        { EnableLegacyPilotSchedule: true },
      ],
      '/profile/manage': true,
      '/data/airports': [{ CrewSchedulingOnly: false }],
      '/data/organization/aircraft': [
        { CrewSchedulingOnly: false, ShowAircraftPage: true },
        {
          CrewSchedulingOnly: true,
          IntegrateLoggingWithCrewScheduleV2: true,
          ShowAircraftPage: true,
        },
      ],
      '/data/organization/fleet-overview': [
        { CrewSchedulingOnly: false, ShowAircraftPage: false },
        {
          CrewSchedulingOnly: true,
          IntegrateLoggingWithCrewScheduleV2: true,
          ShowAircraftPage: false,
        },
      ],
      '/data/organization/company-library': [{ CompanyLibrary: true }],
      '/data/organization/crew-scheduling': [
        { CrewSchedulingOnly: true, CrewSchedulingV1: true, NonAdminCanViewCrewSchedule: true },
      ],
      '/data/organization/crew-scheduling-v3': [
        { CrewSchedulingOnly: true, CrewSchedulingV1: false, NonAdminCanViewCrewSchedule: true },
      ],
      '/data/organization/crew-scheduling-mobile': [
        { CrewSchedulingOnly: true, CrewSchedulingV1: false, NonAdminCanViewCrewSchedule: true },
      ],
    },
    crewScheduleViewer: {
      '/data/aircraft/logs': true,
      '/data/aircraft': true,
      '/profile/duty': [
        { CrewSchedulingOnly: true, NonAdminCanViewCrewSchedule: false },
        { CrewSchedulingOnly: false },
      ],
      '/profile/manage': true,
      '/data/airports': [{ CrewSchedulingOnly: false }],
      '/data/organization/aircraft': [
        { CrewSchedulingOnly: false, ShowAircraftPage: true },
        {
          CrewSchedulingOnly: true,
          IntegrateLoggingWithCrewScheduleV2: true,
          ShowAircraftPage: true,
        },
      ],
      '/data/organization/fleet-overview': [
        { CrewSchedulingOnly: false, ShowAircraftPage: false },
        {
          CrewSchedulingOnly: true,
          IntegrateLoggingWithCrewScheduleV2: true,
          ShowAircraftPage: false,
        },
      ],
      '/data/organization/crew-scheduling': [
        { CrewSchedulingOnly: true, CrewSchedulingV1: true, NonAdminCanViewCrewSchedule: true },
      ],
      '/data/organization/crew-scheduling-v3': [
        { CrewSchedulingOnly: true, CrewSchedulingV1: false, NonAdminCanViewCrewSchedule: true },
      ],
      '/data/organization/crew-scheduling-mobile': [
        { CrewSchedulingOnly: true, CrewSchedulingV1: false, NonAdminCanViewCrewSchedule: true },
      ],
    },
    taggedScheduleViewer: {
      '/data/aircraft/logs': true,
      '/data/aircraft': true,
      '/profile/duty': [
        { CrewSchedulingOnly: true, NonAdminCanViewCrewSchedule: false },
        { CrewSchedulingOnly: false },
      ],
      '/profile/manage': true,
      '/data/airports': [{ CrewSchedulingOnly: false }],
      '/data/organization/aircraft': [
        { CrewSchedulingOnly: false, ShowAircraftPage: true },
        {
          CrewSchedulingOnly: true,
          IntegrateLoggingWithCrewScheduleV2: true,
          ShowAircraftPage: true,
        },
      ],
      '/data/organization/fleet-overview': [
        { CrewSchedulingOnly: false, ShowAircraftPage: false },
        {
          CrewSchedulingOnly: true,
          IntegrateLoggingWithCrewScheduleV2: true,
          ShowAircraftPage: false,
        },
      ],
      '/data/organization/crew-scheduling': [
        { CrewSchedulingOnly: true, CrewSchedulingV1: true, NonAdminCanViewCrewSchedule: true },
      ],
      '/data/organization/crew-scheduling-v3': [
        { CrewSchedulingOnly: true, CrewSchedulingV1: false, NonAdminCanViewCrewSchedule: true },
      ],
      '/data/organization/crew-scheduling-mobile': [
        { CrewSchedulingOnly: true, CrewSchedulingV1: false, NonAdminCanViewCrewSchedule: true },
      ],
    },
    subContractor: {
      '/data/organization/crew-scheduling-v3': [
        { CrewSchedulingOnly: true, CrewSchedulingV1: false },
      ],
      '/data/organization/crew-scheduling-mobile': [
        { CrewSchedulingOnly: true, CrewSchedulingV1: false },
      ],
    },
    user: {
      '/profile/manage': true,
    },
  };

  for (let i = 0; i < roles.length; i += 1) {
    const role = roles[i];
    const matchedPath = pathInPaths(pathname, Object.keys(rolePathMap[role])) || pathname;
    if (rolePathMap[role][matchedPath]) {
      if (typeof rolePathMap[role][matchedPath] === 'boolean') {
        if (rolePathMap[role][matchedPath] === true) {
          return true;
        }
        // eslint-disable-next-line no-continue
        continue;
      }

      if (Array.isArray(rolePathMap[role][matchedPath])) {
        const allowedConditions = rolePathMap[role][matchedPath] as {
          CrewSchedulingOnly?: boolean;
          AerochexEnabled?: boolean;
          CrewSchedulingV1?: boolean;
          IntegrateLoggingWithCrewScheduleV2?: boolean;
          EnableRestAssureIndicator?: boolean;
          CompanyLibrary?: boolean;
          EnableCustomForms?: boolean;
          ManualTrainingItems?: boolean
        }[];

        let isAllowed = [];
        for (let j = 0; j < allowedConditions.length; j += 1) {
          const allowedCondition = allowedConditions[j];
          const restrictions = Object.keys(allowedCondition) as (
            | 'AerochexEnabled'
            | 'CrewSchedulingOnly'
            | 'CrewSchedulingV1'
            | 'IntegrateLoggingWithCrewScheduleV2'
            | 'EnableRestAssureIndicator'
            | 'CompanyLibrary'
            | 'EnableCustomForms'
            | 'ManualTrainingItems'
          )[];

          const pathDetailRestrictions =
            typeof allowedCondition === 'object'
              ? (allowedCondition as {
                  AerochexEnabled?: boolean;
                  CrewSchedulingOnly?: boolean;
                  CrewSchedulingV1?: boolean;
                  IntegrateLoggingWithCrewScheduleV2?: boolean;
                  EnableRestAssureIndicator?: boolean;
                  CompanyLibrary?: boolean;
                  EnableCustomForms?: boolean;
                  ManualTrainingItems?: boolean
                })
              : {};

          // This loop will see if all the properties in the object are true
          isAllowed.push(true);

          for (let k = 0; k < restrictions.length; k += 1) {
            const restriction = restrictions[k];

            if (pathDetailRestrictions[restriction] !== Boolean(settings[restriction])) {
              isAllowed[j] = false;
              break;
            }
          }
        }

        // if one of the conditions is satisfied, then allow user
        if (isAllowed.some(el => el)) {
          return true;
        }

        // eslint-disable-next-line no-continue
        continue;
      }
    }
  }

  // default to disallow since there are many paths we might not have covered and we want a secure default
  return false;
};
