import { EntryType } from './entryTypes';
import { CrewTimelineEntryType } from './timeline/CrewTimelineEntryType';
import { AircraftTimelineEntryType } from './timeline/AircraftTimelineEntryType';
import { MaintenanceTimelineEntryType } from './timeline/MaintenanceTimelineEntryType';

export type ApiEntryType =
  | 'CrewScheduleEntries'
  | 'DutyScheduleEntries'
  | 'DutyTimeEntries'
  | 'AircraftScheduleEntries'
  | 'MaintenanceScheduleEntries';

export const entryTypeMapping: Record<EntryType, ApiEntryType> = {
  'aircraft-schedule': 'AircraftScheduleEntries',
  crewschedule: 'CrewScheduleEntries',
  'duty-schedule': 'DutyScheduleEntries',
  'duty-times': 'DutyTimeEntries',
  'maintenance-crew-schedule': 'MaintenanceScheduleEntries',
};

export const entryTypeLabelsMap: Record<EntryType, string> = {
  crewschedule: 'Crew Schedule',
  'duty-schedule': 'Planned Duty',
  'duty-times': 'Actual Duty',
  'aircraft-schedule': 'Aircraft Schedule',
  'maintenance-crew-schedule': 'Maintenance Schedule',
};

export const apiEntryTypeMap: Record<ApiEntryType, EntryType> = {
  CrewScheduleEntries: CrewTimelineEntryType.CrewSchedule,
  DutyScheduleEntries: CrewTimelineEntryType.DutySchedule,
  DutyTimeEntries: CrewTimelineEntryType.DutyTimes,
  AircraftScheduleEntries: CrewTimelineEntryType.AircraftSchedule,
  MaintenanceScheduleEntries: MaintenanceTimelineEntryType.MaintenanceCrew,
};

export const apiEntryGetPath: Record<
  CrewTimelineEntryType | MaintenanceTimelineEntryType | AircraftTimelineEntryType,
  string
> = {
  [CrewTimelineEntryType.CrewSchedule]: 'crew-schedule',
  [CrewTimelineEntryType.DutySchedule]: 'duty-schedule',
  [CrewTimelineEntryType.DutyTimes]: 'duty',
  [CrewTimelineEntryType.PilotContract]: 'pilot-contract',
  [CrewTimelineEntryType.MeasuringTape]: 'crew-measuring-tape',
  [AircraftTimelineEntryType.MeasuringTape]: 'aircraft-measuring-tape',
  [AircraftTimelineEntryType.AircraftContract]: 'not-implemented',
  [AircraftTimelineEntryType.AircraftSchedule]: 'aircraft-schedule',
  [AircraftTimelineEntryType.MaintenanceAircraftSchedule]: 'maintenance-aircraft-schedule',
  [MaintenanceTimelineEntryType.MaintenanceCrew]: 'maintenance-crew-schedule',
};
