import { combineReducers } from 'redux';
import airports from './airports/reducers';
import aircraft from './aircraft/reducers';
import trainingItems from './training-items/reducers';
import certificates from './certificates/reducers';
import qualifications from './qualifications/reducers';
import certificateGroups from './certificate-groups/reducers';
import aircraftDetails from './aircraft-details/reducers';
import aircraftLogDetails from './aircraft-log/reducers';
import users from './users/reducers';
import companyFlights from './company-flights/reducers';
import aerochex from './aerochex/reducers';
import dutyTimes from './duty-times/reducers';
import flightTimes from './flight-times/reducers';
import currency from './currency/reducers';
import crewCompliance from './crew-compliance/reducers';
import reports from './reports/reducers';
import crewSchedulingV2 from './crew-scheduling-v3/reducers';
import dutyTimeModal from './crew-scheduling-v3/modals/DutyTimeEntryModal/reducers';
import dutyScheduleModal from './crew-scheduling-v3/modals/DutyScheduleEntryModal/reducers';
import scheduleEntryModal from './crew-scheduling-v3/modals/ScheduleEntryModal/reducers';
import maintenanceEntryModal from './crew-scheduling-v3/modals/MaintenanceEntryModal/reducers';
import reviewModal from '../../pages/data/aircraft-log/Review/ReviewModal/reducers';
import crewSchedulingMobile from '../../pages/data/organization/crew-scheduling-mobile/redux-saga/reducers';
import flightWatch from '../../pages/data/organization/flight-watch/redux-saga/reducers';
import pilotStatus from '../../pages/data/organization/pilot-status/redux-saga/reducers';
import aircraftGroupSchedule from '../../pages/data/organization/crew-scheduling-v3/components/GroupSchedule/redux-saga/reducers';
import companyLibrary from '../../pages/data/organization/company-library/redux-saga/reducers';
import customForms from '../../pages/data/organization/custom-forms/redux-saga/reducers';
import crewScheduling from '../../pages/data/organization/crew-scheduling-v3/redux-saga/reducers';
import scheduleVersioning from '../../pages/data/organization/crew-scheduling-v3/components/Versioning/redux-saga/reducers';
import settingsPage from '../../pages/data/organization/settings/redux-saga/reducers.ts';

export default combineReducers({
  airports,
  users,
  companyFlights,
  aircraft,
  aircraftDetails,
  aircraftLogDetails,
  aerochex,
  dutyTimes,
  flightTimes,
  currency,
  crewCompliance,
  reports,
  crewSchedulingV2,
  crewSchedulingMobile,
  dutyTimeModal,
  dutyScheduleModal,
  scheduleEntryModal,
  maintenanceEntryModal,
  reviewModal,
  flightWatch,
  pilotStatus,
  aircraftGroupSchedule,
  companyLibrary,
  customForms,
  crewScheduling,
  scheduleVersioning,
  trainingItems,
  certificates,
  qualifications,
  certificateGroups,
  settingsPage,
});
