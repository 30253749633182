import { all, takeEvery, put, call } from 'redux-saga/effects';
import api from '../../../services/api';
import { actionTypes } from './actions';
import commonActions from '../../common/actions';
import {
  handleFetchReviews,
} from '../../../pages/data/organization/crew-scheduling-v3/redux-saga/workers/timelineEntries/fetchReviews';
import {
  handleFetchReviewTypes
} from '../../../pages/data/organization/crew-scheduling-v3/redux-saga/workers/timelineEntries/fetchReviewTypes';

export function* ADD_LOG_ATTACHMENT({ payload }) {
  const formdata = new FormData();
  formdata.append('file', payload?.file);
  formdata.append(
    'json',
    JSON.stringify({
      Description: payload.description,
      Name: payload.name,
      FlightID: payload.aircraftLogId,
    }),
  );

  try {
    const response = yield call(
      api.post,
      `/v1/aircraft-logs/${payload.aircraftLogId}/attachments/v2/${payload.attachmentId}`,
      formdata,
    );

    if (response.status === 200) {
      yield put({
        type: actionTypes.ADD_LOG_ATTACHMENT_SUCCESS,
        payload: true,
      });
    }
  } catch (error) {
    const errorData = error?.response?.data;
    yield put({ type: actionTypes.ADD_LOG_ATTACHMENT_ERROR });
    yield put(
      commonActions.setNotifire({
        type: 'error',
        message: `Error ${errorData?.Code}`,
        description: errorData?.Message,
      }),
    );
  }
}

export function* DELETE_LOG_ATTACHMENT({ payload }) {
  try {
    const response = yield call(
      api.delete,
      `/v1/aircraft-logs/${payload.aircraftLogId}/attachments/v2/${payload.attachmentId}`,
    );

    if (response.status === 204) {
      yield put({
        type: actionTypes.DELETE_LOG_ATTACHMENT_SUCCESS,
        payload: true,
      });
    }
  } catch (error) {
    const errorData = error?.response?.data;
    yield put({ type: actionTypes.DELETE_LOG_ATTACHMENT_ERROR });
    yield put(
      commonActions.setNotifire({
        type: 'error',
        message: `Error ${errorData?.Code}`,
        description: errorData?.Message,
      }),
    );
  }
}

export function* EDIT_LOG_ATTACHMENT({ payload }) {
  const data = {
    Name: payload.name,
    Description: payload.description,
    Type: payload.type,
  };

  try {
    const response = yield call(
      api.patch,
      `/v1/aircraft-logs/${payload.aircraftLogId}/attachments/v2/${payload.attachmentId}/meta`,
      data,
    );

    if (response.status === 200) {
      yield put({
        type: actionTypes.EDIT_LOG_ATTACHMENT_SUCCESS,
        payload: true,
      });
    }
  } catch (error) {
    const errorData = error?.response?.data;
    yield put({ type: actionTypes.EDIT_LOG_ATTACHMENT_ERROR });
    yield put(
      commonActions.setNotifire({
        type: 'error',
        message: `Error ${errorData?.Code}`,
        description: errorData?.Message,
      }),
    );
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actionTypes.ADD_LOG_ATTACHMENT_REQEST, ADD_LOG_ATTACHMENT),
    takeEvery(actionTypes.DELETE_LOG_ATTACHMENT_REQEST, DELETE_LOG_ATTACHMENT),
    takeEvery(actionTypes.EDIT_LOG_ATTACHMENT_REQEST, EDIT_LOG_ATTACHMENT),
    takeEvery(actionTypes.FETCH_LOG_REVIEWS, handleFetchReviews),
    takeEvery(actionTypes.FETCH_LOG_REVIEW_TYPES, handleFetchReviewTypes),
  ]);
}
