import {
  selectContextMenuClickPosition,
  selectCopyItemsSaga,
  selectCutItemsSaga,
} from '../../selectors-saga';
import { call, put } from 'redux-saga/effects';
import { CrewTimelineItem } from '../../../components/Timeline/CrewTimeline/generator';
import { AircraftTimelineItem } from '../../../components/Timeline/AircraftTimeline/generator';
import moment from 'moment-timezone';
import { HandlePasteTimelineItemsAction, scheduleActions } from '../../actions';
import { ContextMenuClickPosition, TimelineType } from '../../../types/timeline';
import { handleCopyAndPaste, handleCutAndPaste } from './utils';
import { handleDeleteEntry } from './handleDeleteEntry';
import { MaintenanceTimelineItem } from '../../../components/Timeline/MaintenanceTimeline/generator';

export function* handlePasteTimelineItems(action: HandlePasteTimelineItemsAction) {
  const { timelineType } = action.payload;
  const cutItems = yield* selectCutItemsSaga();
  const copyItems = yield* selectCopyItemsSaga();
  const position = yield* selectContextMenuClickPosition();

  if (copyItems.length > 0) {
    timelineType === TimelineType.Crew
      ? yield put(scheduleActions.setSelectedItems([]))
      : yield put(scheduleActions.setSelectedAircraftItems([]));
    yield call(handleBulkCopyPaste, copyItems, position);
  } else if (cutItems.length > 0) {
    timelineType === TimelineType.Crew
      ? yield put(scheduleActions.setSelectedItems([]))
      : yield put(scheduleActions.setSelectedAircraftItems([]));
    yield call(handleBulkCutPaste, cutItems, position);
  }
}

export function* handleBulkDelete(
  items: (CrewTimelineItem | AircraftTimelineItem | MaintenanceTimelineItem)[],
  timelineType: TimelineType,
) {
  try {
    for (let i = 0; i < items.length; i++) {
      yield* handleDeleteEntry(items[i], timelineType);
    }
  } catch (e) {
    console.log('Failed to bulk delete entries:', e);
  }
}

function* handleBulkCopyPaste(
  items: (CrewTimelineItem | AircraftTimelineItem)[],
  clickPosition: ContextMenuClickPosition,
) {
  try {
    yield put(scheduleActions.setState({ isShowTimelineLoaded: true }));

    const sortedItems = sortItemsByTime(items);
    let cumulativeTimeDiff = 0;

    const firstItemStartTime = yield* handleCopyAndPaste(
      sortedItems[0],
      clickPosition,
      cumulativeTimeDiff,
    );

    for (let i = 1; i < sortedItems.length; i++) {
      const copyItem = sortedItems[i];

      if (i > 0) {
        cumulativeTimeDiff += Math.abs(
          moment(copyItem.start_time).diff(moment(sortedItems[i - 1].start_time), 's'),
        );
      }

      yield* handleCopyAndPaste(copyItem, clickPosition, cumulativeTimeDiff, firstItemStartTime);
    }
  } catch (e) {
    console.log('Failed to bulk copy and paste:', e);
  } finally {
    yield put(scheduleActions.setState({ isShowTimelineLoaded: false }));
  }
}

function* handleBulkCutPaste(
  items: (CrewTimelineItem | AircraftTimelineItem)[],
  clickPosition: ContextMenuClickPosition,
) {
  try {
    yield put(scheduleActions.setState({ isShowTimelineLoaded: true }));

    const sortedItems = sortItemsByTime(items);
    let cumulativeTimeDiff = 0;
    const firstItemStartTime = yield* handleCutAndPaste(
      sortedItems[0],
      clickPosition,
      cumulativeTimeDiff,
    );

    for (let i = 1; i < sortedItems.length; i++) {
      const copyItem = sortedItems[i];

      if (i > 0) {
        cumulativeTimeDiff += Math.abs(
          moment(copyItem.start_time).diff(moment(sortedItems[i - 1].start_time), 's'),
        );
      }

      yield* handleCutAndPaste(copyItem, clickPosition, cumulativeTimeDiff, firstItemStartTime);
    }
  } catch (e) {
    console.log('Failed to bulk cut and paste:', e);
  } finally {
    yield put(scheduleActions.setState({ isShowTimelineLoaded: false }));
  }
}

function sortItemsByTime(items: (CrewTimelineItem | AircraftTimelineItem)[]) {
  return items.sort((a, b) => {
    if (a.start_time !== b.start_time) {
      return a.start_time - b.start_time;
    }
    return a.end_time - b.end_time;
  });
}
